if($('body').hasClass('home')){
    if ($(window).width() < 991) {

    }
    else {
      window.onscroll = function () {
          scrollRotate();
      };
          function scrollRotate() {
              let image = document.getElementById("sun");
              image.style.transform = "translateY(-" + window.pageYOffset/7 + "%)";
          }
    }
}
/*Header */
/*scroll header if not default template*/
if($('body').hasClass('page-template-default')){
    $('.header_wrapper').addClass('scrolled');
}
else{
  $(window).scroll(function() {
    if ($(document).scrollTop() > 80) {
        $('.header_wrapper').addClass('scrolled');
    }
    else{
      $('.header_wrapper').removeClass('scrolled');
    }
  });
}

/*Detect Direction*/
var lastScrollTop = 0;
$(window).scroll(function(event){
   var st = $(this).scrollTop();
   if (st > lastScrollTop){
       // downscroll code
       $('.header_wrapper').addClass('down');
   } else {
      // upscroll code
      $('.header_wrapper').removeClass('down');
   }
   lastScrollTop = st;
});

/*Language Switcher*/
$(".language.is-active").on('click', function() {
    return false;
})

/*Open Menu*/
$(".menu_open button").on('click', function() {
    if($(this).hasClass('is-active')){
        $("body").css('overflow-y','scroll');
        $(this).removeClass('is-active');
        $('.headermenu').removeClass('is-active');
        $('.header_wrapper').removeClass('openmenu');
        $('.menu li').removeClass('aos-animate');
      }
      else{
        $(this).addClass('is-active');
        jQuery("body").css('overflow-y','hidden');
        $('.headermenu').addClass('is-active');
        $('.header_wrapper').addClass('openmenu');
        setTimeout(
        function()
        {
          $( ".menu li" ).each(function( index ) {
                    $(this).addClass('aos-animate');
                    $(this).addClass('aos-init');
          });
      }, 1000);
    }
});


/*Menu Animation*/
$( document ).ready(function() {
  $( ".menu li" ).each(function( index ) {
      $(this).attr('data-aos','fade-up');
      $(this).attr('data-aos-duration','600');
      $(this).addClass('aos-init');
      setTimeout(function() {$( ".menu li" ).removeClass('aos-animate');  }, 300);
      if(index==0){
          $(this).attr('data-aos-delay',100);
      }else{
        $(this).attr('data-aos-delay',index*150);
      }
  });
});


/*Homepage*/
/*Animation Title Featured*/
/*Split Title*/
$( document ).ready(function() {
  $( ".title h1" ).addClass('ml6');
  $( ".title h1>span" ).addClass('text-wrapper');

  $( ".wrapper h2" ).addClass('title_animation');
  $( ".wrapper h2>span" ).addClass('title_animation_line');
});



(function($) {
  var s,
  spanizeLetters = {
    settings: {
      letters: $('.title h1 span'),
    },
    init: function() {
      s = this.settings;
      this.bindEvents();
    },
    bindEvents: function(){
      s.letters.html(function (i, el) {
        //spanizeLetters.joinChars();
        var spanizer = $.trim(el).split("");
        return '<span>' + spanizer.join('</span><span>') + '</span>';
      });
    },
  };
  spanizeLetters.init();
})(jQuery);

(function($) {
  var s,
  spanizeLetters = {
    settings: {
      letters: $('.wrapper h2 span'),
    },
    init: function() {
      s = this.settings;
      this.bindEvents();
    },
    bindEvents: function(){
      s.letters.html(function (i, el) {
        //spanizeLetters.joinChars();
        var spanizer = $.trim(el).split("");
        return '<span>' + spanizer.join('</span><span>') + '</span>';
      });
    },
  };
  spanizeLetters.init();
})(jQuery);

$( document ).ready(function() {
  $( ".title h1 span span" ).each(function( index ) {
      $(this).addClass('letter');
  });
  $( ".wrapper h2 span span" ).each(function( index ) {
      $(this).addClass('letter');
  });
});


  setTimeout(function() {
    // Wrap every letter in a span
    var textWrapper = document.querySelector('.title h1 span span');
    textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    anime.timeline({loop: false})
    .add({
      targets: '.title',
      opacity: 1,
      duration: 100,
      easing: "easeOutExpo"
    }).add({
        targets: '.ml6 .letter',
        translateY: ["1.1em", 0],
        translateZ: 0,
        duration: 1500,
        delay: (el, i) => 50 * i,
        easing: "easeOutExpo",
        opacity:1
      }).add({
        targets: '.ml6',
        opacity: 1,
        duration: 1000,
        easing: "easeOutExpo",
        delay: 1000
      });

  }, 100);


  $( document ).ready(function() {
    $( ".title h1 span span" ).each(function( index ) {
        if($(this).text()==' '){
          $(this).addClass('empty');
        }
    });

    $( ".wrapper h2 span span" ).each(function( index ) {
        if($(this).text()==' '){
          $(this).addClass('empty');
        }
    });
  });


  /*Animation TItles Functions*/
  var flag=1;//global var
  var flag_restaurant=1;//global var
  if($('body').hasClass('home')){
    var targetOffset = $("#about").offset().top - $('.header_wrapper').height()*3;
    var targetOffset_restaurant = $("#restaurant").offset().top - $('.header_wrapper').height();
    var w = $(window).scroll(function(){
        if ( w.scrollTop() > targetOffset ) {
          animation_title_function(flag);
        }
      });

    var x = $(window).scroll(function(){
          if ( x.scrollTop() > targetOffset_restaurant ) {
            animation_title_function2(flag_restaurant);
          }
        });
    }
/*Animation title About*/
function animation_title_function(flags){
    if(flags==1){
      setTimeout(function() {

        var textWrapper2 = document.querySelector('.wrapper h2 span span');
        textWrapper2.innerHTML = textWrapper2.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

        anime.timeline({loop: false})
        .add({
          targets: '.title_animation_wrapper',
          opacity: 1,
          duration: 100,
          easing: "easeOutExpo"
        }).add({
            targets: '.title_animation .letter',
            translateY: ["1.1em", 0],
            translateZ: 0,
            duration: 1500,
            delay: (el, i) => 50 * i,
            easing: "easeOutExpo",
            opacity:1
          }).add({
            targets: '.title_animation',
            opacity: 1,
            duration: 1000,
            easing: "easeOutExpo",
            delay: 1000
          });
      }, 100);
    }
    flag=0;
}

/*Animation title Restaurant*/
function animation_title_function2(flags){
    if(flags==1){
      setTimeout(function() {

        var textWrapper2 = document.querySelector('.wrapper h2 span span');
        textWrapper2.innerHTML = textWrapper2.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

        anime.timeline({loop: false})
        .add({
          targets: '.title_animation_wrapper',
          opacity: 1,
          duration: 100,
          easing: "easeOutExpo"
        }).add({
            targets: '.title_animation .letter',
            translateY: ["1.1em", 0],
            translateZ: 0,
            duration: 1500,
            delay: (el, i) => 50 * i,
            easing: "easeOutExpo",
            opacity:1
          }).add({
            targets: '.title_animation',
            opacity: 1,
            duration: 1000,
            easing: "easeOutExpo",
            delay: 1000
          });
      }, 100);
    }
    flag_restaurant=0;
}

  /*Dine Page*/
  //dine menu toggle button
  // if($('body').hasClass('page-template-tmpl_dine')){
  //   $( "#dine-menu .item-header" ).on( "click", function() {
  //     $(this).parent().toggleClass('active');
  //   });
  // }


/*Gallery*/
          import Swiper, { Navigation } from 'swiper';
          const gallery_swi = new Swiper(".gallery_swipe", {
            slidesPerView: "1",
            centeredSlides: false,
            navigation: {
             nextEl: '.swiper-button-next',
             prevEl: '.swiper-button-prev',
           }
          });

/*Slider Gastronomy Section Homepage*/
          const gallery_type = new Swiper(".gallery_type", {
            slidesPerView: "1",
            centeredSlides: false,
            navigation: {
             nextEl: '.swiper-button-next',
             prevEl: '.swiper-button-prev',
           }
          });
